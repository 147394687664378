// There appears to be a bug with the MCP CSS library where hovering
// over a button causes the text color to change to blue, effectively
// hiding the text for primary (aka blue) buttons.
// TODO: remove this override once the MCP CSS library is fixed.

$dark: #303233;
$middark: #7a7d80;
$midlight: rgb(214, 214, 214);
$highlight: rgb(250, 239, 208);
$light: rgb(239, 243, 245);

@function colorPalette($RGBColor, $opacityLevel) {
  @return rgba($RGBColor, $opacityLevel);
}

.re-appShellLowerWrapper__mainContent {
  margin-right: 3%;
  margin-left: 3%;

  .css-q5ra38 {
    padding-left: 0px;
  }
}

.card-header {
  font-weight: bold;
}

.org-info {
  margin-bottom: 15px;

  .title {
    font-size: 24pt;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .links {
    margin-left: 5px;

    span {
      margin-right: 35px;
      font-weight: bold;
      font-size: 10pt;
    }
  }

  svg {
    position: relative;
    top: 5px;
    right: 5px;
    color: $middark;
  }
}

.network {
  margin-top: -35px;
}

.tabbedInfo {
  .crc-navtab {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(167, 174, 182, 0) 0%, rgba(167, 174, 182, .6) 3%, rgba(167, 174, 182, .4) 50%, rgba(196, 205, 214, 0) 70%) 0 0 100% 0/3px 0 1px 0 stretch;

    svg {
      margin-right: 4px;
      position: relative;
      top: 4px;
      font-size: 18px;
    }
  }

  .nav-tabs>li {
    margin-bottom: 0px;
  }

  .css-1p4b36g>li>a,
  .css-1p4b36g>li>button {
    font-weight: 600;
  }

}

.org-card {

  .card-header {
    background-color: colorPalette($dark, .4);
    color: white;
    font-size: 12pt;
    padding: 14px;

    a {
      color: white;
    }
  }

  &.crc-card {
    background-color: rgba(255, 255, 255, 0.5);
    padding-top: 10px;
    margin-bottom: 20px;
    margin-top: -10px
  }

  &.slack-explanation {
    h2 {
      margin-top: 5px;
    }

    img {
      border: 1px solid black;
      margin: 20px;
    }
  }

  &.domainCard {
    min-height: 100px;
    padding-top: 0px;
    text-align: center;
    min-height: 330px;
    max-width: 200px;
    min-width: 200px;

    .title {
      font-weight: bold;
      font-size: 16px;
      display: block;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    .imageHolder {
      height: 210px;

      img {
        border: 1px solid var(--ccl-color-border-default, #c4cdd6)
      }
    }

    .crc-card-header {
      height: 58px;
    }

    &:hover {
      // background-color: $highlight;
      border: 1px solid #f85a01;
      -o-transition: .5s;
      -ms-transition: .5s;
      -moz-transition: .5s;
      -webkit-transition: .5s;
      /* ...and now for the proper property */
      transition: .5s;
      cursor: pointer;
    }
  }
}

.simple-leadership {
  margin-bottom: 40px;
}

.centered {
  margin: auto;
  width: 100%;
  margin-top: 15px;
}

.profile.large {
  min-width: 512px;
  max-width: none;
  min-height: none;
  float: left;
  text-align: left;
  margin-bottom: 20px;

  .profile-overlay {
    position: absolute;
    left: 0px;
    z-index: 3;
    padding: 5px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid gray;
  }

  p {
    display: inline;
    margin: 0px;
    padding-left: 10px;
  }

  .title {
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    max-width: 512px;
    min-width: 300px;
    display: block;
    height: 35px;
    margin-top: 3px;
  }
}

.profile {
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: 1px solid $middark;
  display: inline-block;
  overflow: hidden;
  align-self: flex-start;
  min-width: 194px;
  max-width: 194px;
  min-height: 355px;
  padding-bottom: 8px;

  .associations {
    text-align: left;
    position: relative;
    top: -182px;
    left: 8px;
    width: 100%;
    height: 0px;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    max-width: 192px;
    padding-top: 5px;
    height: 45px;
    line-height: 17px;
  }

  p {
    line-height: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.crc-tooltip__content {

  .fix {
    color: white
  }
}

.orphan-info {
  svg {
    position: relative;
    top: 4px;
    margin-right: 4px;
  }

  ;
  font-weight: bold;
  margin-bottom: 8px;
}

.apis {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;

  .warning {
    white-space: nowrap;
  }

  h2 {
    font-weight: 500;
  }

  a {
    margin-left: 2px;
  }

  svg {
    position: relative;
    top: 1px
  }

  p {
    padding-left: 2px;
    padding-right: 2px;
  }

  .deprecated a {
    color: grey
  }

  h3 {
    margin-top: 20px;
    line-height: 20px;
  }
}

.user-info {
  margin-left: 5%;
  margin-top: -60px;
  padding-right: 5%;
  width: 100%;
}

.first-column {
  position: sticky;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #0088a9;
  text-align: left;
}

.link-button:hover,
.link-button:focus {
  text-decoration: underline;
  border: none;
}

.footer {
  position: fixed;
  background-color: rgba(black, 0.08);
  text-align: center;
  border-top: 1px solid #c4cdd6;
  border-left: 0px solid #c4cdd6;
  border-bottom: 0 !important;
  border-right: 0 !important;
  padding: 10px;
  bottom: 0px;
  width: 150px !important;
  right: 0px;
  border-radius: 0px 0px 0px 0px;
  margin-bottom: 0px;
}


.re-appShell {
  h2 {
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
  }

  .ctrf-feedback-sticker-container {
    background-color: rgba(black, 0.08);
    color: var(--ccl-color-text-interactive, #0088A9);
    border-top: 1px solid #c4cdd6;
    border-left: 1px solid #c4cdd6;
    border-bottom: 0px;
    bottom: 0px;
    padding: 10px;
    right: 150px;
    text-align: center;
    border-radius: 10px 0px 0px 0px;
    animation: none;
  }
}

.download {
  text-align: right;
  line-height: 30px;

  svg {
    position: relative;
    top: 6px;
    left: 8px
  }

  margin-bottom: 5px;
  margin-right: 8px;
}

.shimmer {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #c4cdd6;
  background: linear-gradient(to right, #edf3f8 8%, white 18%, #edf3f8 33%);
  background-size: 1200px 100%;
}


@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 0;
  border-top: 1px solid #edf3f8;
}