.network-toolbar {
  > button.btn {
    padding: 8px;
    float: left;
    min-width: 100px;
    border: 1px solid transparent;
    color: #8090a2;
  }
  .dropdown {
    float: left;
  }
  > .dropdown {
    border:0px;
    margin-left: 15px;
    > button.btn.dropdown-toggle {
      padding: 8px;
      border: 0px!important;
    }
  }
  > .dropdown.open {
    border:0px;
    > button.btn.dropdown-toggle {
      padding: 8px;
      border: 0px;
    }
  }
  .css-rmnr5y {
    float: left;
  }
  width: 100%;
  min-height: 40px;
  background-color: white;
}

.network-options {
  .color-list {
    margin-top: 15px;
    .btn-group.btn-group-justified {
      margin-bottom: 20px;
      button {
        width: 50%;
      }
    }
  }
  .partition-subject {
    td:nth-child(2) {
      width: 100%;
    }
    td {
      padding: 4px;
    }
    text-align: left;
    border-bottom: 1px solid #DDDDDD;
    font-size: 10pt;
    button {
      max-width: 20px;
      padding: 10px;
    }
  }
  h2 {
    margin: 0px;
  }
}

.drawer-content {
  .drawer-body, .modal-body {
    padding-top:0px;
  }
}

circle {
  &.background {
    fill-opacity: .2;
    stroke-opacity: 0;
  }
  &.highlight {
    fill-opacity: 1;
  }
}

path {
  &.highlight {
    stroke-width: 8;
    stroke: yellow;
    stroke-opacity: 1;
  }
  &.background {
    stroke-opacity: .15;
  }
}

text {
  &.highlight {
    font-size: 28pt;
    font-weight: bold;
    fill: black;
    fill-opacity: 1
  }
  &.background {
    opacity: .1;
  }
}

text {
  pointer-events: none;
  user-select: none;
}

.svgViewer {
  width: 50%;
  float: left
}

.network-profile {
  float: right;
  margin-right: 5vw;
  margin-top: 5vh;
}